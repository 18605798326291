// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { connectStorageEmulator, getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFbKcFXyjpK-d4DOyOl3RMxoAAXMruAUQ",
  authDomain: "readtok-45464.firebaseapp.com",
  projectId: "readtok-45464",
  storageBucket: "readtok-45464.appspot.com",
  messagingSenderId: "513487251162",
  appId: "1:513487251162:web:8d85937a7c9071c0e827e3",
  measurementId: "G-XLJBT6YGVW"
};

// Initialize Firebase
const firebase =  initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const db = getFirestore(firebase);


const auth = getAuth(firebase);
const storage = getStorage(firebase)
const functions = getFunctions(firebase);

  // connectAuthEmulator(auth, "http://127.0.0.1:9099");
  // connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  // connectFirestoreEmulator(db, '127.0.0.1', 8080);
  // connectStorageEmulator(storage, '127.0.0.1', 9199);


export default firebase
export {
  auth,
  db,
  storage,
  functions
}