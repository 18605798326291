import pptxgen from "pptxgenjs"

// JSON input
const slidesDataExample = [
    {
        "slideNumber": 1,
        "title": "Overview of the Process",
        "content": {
            "type": "text",
            "text": "This presentation explains a process to handle queries using keyword extraction, vectorization, and similarity computation to get relevant documents and responses."
        }
    },
    {
        "slideNumber": 2,
        "title": "Step 1: Extract Keywords",
        "content": {
            "type": "text",
            "text": "The process starts with a query. Keywords are extracted from the query to filter relevant options."
        }
    },
    {
        "slideNumber": 3,
        "title": "Step 2: Vectorize the Query",
        "content": {
            "type": "text",
            "text": "After extracting keywords, the query is vectorized. This means converting the query into a format that can be used for similarity computation."
        }
    },
    {
        "slideNumber": 4,
        "title": "Step 3: Filter Options Based on Keywords",
        "content": {
            "type": "text",
            "text": "Options are filtered based on the extracted keywords. These filtered options have vectorized descriptions, ready for the next step."
        }
    },
    {
        "slideNumber": 5,
        "title": "Step 4: Compute Vector Similarity",
        "content": {
            "type": "text",
            "text": "Compute the similarity between the vectorized query and the vectorized descriptions of the filtered options. This step returns the top K results."
        }
    },
    {
        "slideNumber": 6,
        "title": "Step 5: Get Top K Documents",
        "content": {
            "type": "text",
            "text": "The top K documents (texts) are selected based on the vector similarity scores. These documents, along with the original query text, are used for further processing."
        }
    },
    {
        "slideNumber": 7,
        "title": "Step 6: Process with GPT-4",
        "content": {
            "type": "text",
            "text": "The top K documents and the query text are processed using GPT-4 to generate the final result."
        }
    },
    {
        "slideNumber": 8,
        "title": "Final Result",
        "content": {
            "type": "text",
            "text": "The final result consists of a response generated by GPT-4 and the relevant documents retrieved from the database."
        }
    },
    // {
    //     "slideNumber": 9,
    //     "title": "Diagram Overview",
    //     "content": {
    //         "type": "picture",
    //         "path": "/mnt/data/TC db search_240614_153429.jpeg"
    //     }
    // }
];

// Function to create a presentation based on JSON input
export const createPresentation = async (slidesData) => {
    let pptx = new pptxgen();

    slidesData.forEach(slideData => {
        let slide = pptx.addSlide();

        // Add title
        slide.addText(slideData.title, { x: 0.5, y: 0.5, fontSize: 24, bold: true });

        // Add content based on type
        switch (slideData.content.type) {
            case "text":
                slide.addText(slideData.content.text, { x: 0.5, y: 1.5, fontSize: 18 });
                break;
            case "bulletPoints":
                slide.addText(slideData.content.points.map((point, index) => `${index + 1}. ${point}`).join('\n'), { x: 0.5, y: 1.5, fontSize: 18 });
                break;
            case "picture":
                slide.addImage({ path: slideData.content.path, x: 1, y: 1.5, w: 4, h: 3 });
                break;
            default:
                console.error(`Unknown content type: ${slideData.content.type}`);
        }
    });

    // Save the presentation
    let presentation;
    pptx.writeFile({fileName: 'GeneratedPresentation.pptx'}).then(file => {
      console.log('PPT FILE', file);
      presentation = file;
    }).catch(err => console.log('ERROR PPT: ', err));
    let file = await pptx.writeFile({fileName: 'GeneratedPresentation.pptx'});
    console.log('PRESENTATION FILE: ', file);
    return file;
}

// Create the presentation
// createPresentation(slidesData);
