import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../contexts/authContext";
import { auth, db } from "../api/firebase";
import { signOut } from "firebase/auth";

import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  RichTextReadOnly
} from "mui-tiptap";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import Login from "./login";
import Reel from "../components/reel";
import { httpsCallable } from "firebase/functions";
import { functions } from "../api/firebase";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { TextSnippet } from "@mui/icons-material";
import { createPresentation } from "../api/ppt";

const stickyColors = [	"#ff7eb9", "#ff65a3", "#7afcff", "#feff9c", "#fff740" ];

const Reader = () => {
  const [ content, setContent ] = useState('');
  const [ presentationContent, setPresentationContent ] = useState();
  const [ open, setOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ messages, setMessages ] = useState([]);
  const rteRef = useRef(null);
  const { id } = useParams();
  console.log(id)

  
  const getData = async (id) => {
    const q = query(collection(db, "presentations"), where("notesId", "==", id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const msgs = [];
      querySnapshot.forEach((doc) => {
          console.log('dupa: ', doc.data().content);
          const content = JSON.parse(doc.data().content);
          setPresentationContent(content.slides);
          try {
            content.forEach(slide => {
              msgs.push(slide.title);
            });
          } catch(err) {
            try {
              content.slides?.forEach(slide => {
                msgs.push(slide.title);
              });
            } catch (err) {

            }
          }
      });
      setMessages(msgs);
      // createContent(msgs)
    });
  }

  useEffect(() => {
    getData(id);
  }, [id]);

  const createContent = (messages) => {
    const listMessages = messages.map(text => `<li>${text}</li>`);
    console.log(`<ul>${listMessages.join('')}</ul>`)
    setContent(`<ul>${listMessages.join('')}</ul>`)
  }

  const navigate = useNavigate();

  const logOut = async () => {
    await signOut(auth)
      .then(() => {
        console.log("user signed out");
      })
      .catch((err) => {
        console.log("err user signOut ", err);
      });
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setIsLoading(false);
    setOpen(true);
  }

  const handlePpt = () => {
    createPresentation(presentationContent)
  }

  return (
    <Box sx={{
      width: "100%",
      height: "calc(100vh - 80px)",
      display: "flex",
      flexDirection: "column",
      "& .MuiTiptap-RichTextContent-root": {
        height: "100%"
      },
      "& .MuiTiptap-FieldContainer-root": {
        height: "100%"
      },
      "& .MuiTiptap-MenuBar-root": {
        backgroundColor: "#f4f3d7",
        minHeight: "50px !important"
      },
      "& .MuiTiptap-FieldContainer-root": {
        overflow: "scroll"
      },
      "& .MuiTiptap-FieldContainer-outlined": {
        border: "none",
        borderRadius: 0,
        padding: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column"
      },
      "& .css-anumxs-FieldContainer-notchedOutline": {
        border: "none",
        width: "100%"
      },
      "& .tiptap.ProseMirror": {
        width: "100%",
        height: "100%",
        overflow: "scroll",
        wordBreak: "break-all"
      }
    }}>
      <Box sx={{ textAlign: "center", margin: "1em" }}>
        <Typography variant="h5" component="h5" >Give any text and get easy to swallow messages</Typography>
      </Box>
      
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "flex-start", overflow: "scroll" }}>
        {messages.map((text, i) => 
          <Box sx={{ boxShadow: "5px 5px 7px rgba(33,33,33,.7)", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1em", padding: "1em", width: "calc(100% - 10px)", minHeight: "200px", aspectRatio: "1 / 1", backgroundColor: stickyColors[i % 5] }}>
            <Typography variant="h5" component="h5" >{text}</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Button fullWidth={true} variant="contained" onClick={handleOpen}>Play</Button>
        <Button fullWidth={true} variant="contained" onClick={handlePpt}>Download presentation</Button>
      </Box>
      {messages && messages.length ? <Reel handleClose={handleClose} open={open} data={messages} /> : null}
    </Box>
  );
};

export default Reader;