import react, { useContext, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { AuthContext } from "../contexts/authContext";
import { Avatar, Box, Button, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import StickyNote2TwoToneIcon from '@mui/icons-material/StickyNote2TwoTone';
import { Logout, Login, Notes, StickyNote2 } from "@mui/icons-material";
import { signOut } from "firebase/auth";
import { auth } from "../api/firebase";
import Documents from "./documents";

const notes = ["pan tadeusz", "chlopi", "wesele", "nad niemnem", "potop", "pan kleks", "ogniem i mieczem", "granice", "pan tadeusz", "chlopi", "wesele", "nad niemnem", "potop", "pan kleks", "ogniem i mieczem", "granice"]

function Menu ({ handleDrawerToggle }) {
  const { loading, user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const logOut = async () => {
    await signOut(auth)
      .then(() => {
        console.log("user signed out");
        handleDrawerToggle();
        navigate('/')
      })
      .catch((err) => {
        console.log("err user signOut ", err);
      });
  };

  const handleLogin = () => {
    handleDrawerToggle();
    navigate('/login');
  }

  const handleGoHome = () => {
    handleDrawerToggle();
    navigate('/');
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box component={Button} onClick={handleGoHome} sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "24px" }}>
        <Avatar sx={{ background: "none", marginRight: "8px", height: "28px", width: "28px"  }}><StickyNote2TwoToneIcon sx={{ color: "rgba(0, 0, 0, .58)" }} /></Avatar>
        <Typography variant="h6" component="h6">ReadTok</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "scroll" }}>
        {user && <Documents user={user} handleDrawerToggle={handleDrawerToggle} />}
      </Box>
      <Divider />
      <List>
        {user && !user.isAnonymous ?
        <ListItemButton onClick={logOut}>
          <ListItemIcon><Logout /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton> : null}
        {user && user.isAnonymous ? 
        <ListItemButton onClick={handleLogin}>
          <ListItemIcon><Login /></ListItemIcon>
          <ListItemText primary="Login" />
        </ListItemButton> : null}
        <ListItemButton onClick={() => navigate('/')}>
          <ListItemIcon><Notes /></ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </List>
    </Box>
  )
}

export default Menu;