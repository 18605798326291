import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
import { auth, db } from "../api/firebase";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { Notes } from "@mui/icons-material";

function Documents ({ user, handleDrawerToggle }) {
  const [ documents, setDocuments ] = useState([]);
  const navigate = useNavigate();
  console.log('user: ', user);

  const getData = async () => {
    const q = query(collection(db, "documents"), where("uid", "==", user.uid), orderBy("timestamp"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
          docs.push(doc.data());
      });
      setDocuments(docs);
    });
  }
  
  useEffect(() => {
    getData();
  }, [])

  const handleClick = (id) => {
    navigate(`/reader/${id}`);
    handleDrawerToggle();
  }
  return (
    <List>
      {documents.map((doc) => {
        return (<ListItemButton onClick={() => handleClick(doc.notesId)}>
          <ListItemIcon><Notes /></ListItemIcon>
          <ListItemText primary={doc.title} />
        </ListItemButton>)
      })}
    </List>
  )
}

export default Documents;