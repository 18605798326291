import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "../api/firebase";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Box, Button, TextField } from "@mui/material";
import Footer from "../components/footer";
import { Google } from "@mui/icons-material";

const Login = () => {
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const provider = new GoogleAuthProvider();

  //! for getting the user email and password value
  const handleOnChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setUserCredentials((prev) => ({ ...prev, [fieldName]: fieldValue }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //! clearing error state on submit
    setError("");

    //! setting loading to true in order to show the loadin state
    setIsLoading(true);
    console.log("submit clicked");

    try {
      const { email, password } = userCredentials;
      const user = await signInWithEmailAndPassword(auth, email, password);

      console.log("currentUser", user);
    } catch (err) {
      //! getting the error message from firebase and setting it in the state to show
      setError(err?.message || "Something went wrong!");

      console.log("error", err);
    }

    //! setting the  loading to false at the end of our submit execution
    setIsLoading(false);
  };

  const handleLoginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log("currentUser", user);

      navigate('/app');
    } catch (err) {
      setError(err?.message || "Something went wrong");
      console.log("error", err)
    }
  }

  return (
    <Box sx={{ textAlign: "center", maxWidth: "800px", justifyContent: "center", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
      <form onSubmit={onSubmit} className="form">
        <Box>
          <TextField
            required
            type="email"
            name="email"
            placeholder="Email"
            value={userCredentials.email}
            onChange={handleOnChange}
          />
        </Box>
        <Box sx={{ marginTop: "1em" }}>
          <TextField
            required
            type="password"
            name="password"
            placeholder="Password"
            value={userCredentials.password}
            onChange={handleOnChange}
          />
        </Box>
        {/* for navigating to signup page */}
        <p className="navigate-text">
          Need an account?{" "}
          <span onClick={() => navigate("/signup")}>Sign Up</span>{" "}
        </p>

        {/* Error message if there is any error */}
        {error && <p className="error-text">{error}</p>}

        {/* disabling the button if there is any loading means a request is in process */}
        <Box sx={{ padding: '1em 16px' }}>
          <Button variant="contained" type="submit" disabled={isLoading} fullWidth={true}>
            {isLoading ? "Loading..." : " Login"}
          </Button>
        </Box>
        <Box sx={{ padding: '1em 16px' }}>
          <Button variant="contained" onClick={handleLoginWithGoogle} disabled={isLoading} startIcon={<Google />} fullWidth={true}>
            {isLoading ? "Loading..." : " Login withb Google"}
          </Button>
        </Box>
      </form>
    </Box>
    <Footer />
  </Box>
  );
};

export default Login;