import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AuthProvider, { AuthContext } from "./contexts/authContext";
import Login from "./screens/login";
import Signup from "./screens/signup";
import NotFound from "./screens/notfound";
import Reader from "./screens/reader";
import Home from "./screens/home";
import Landing from "./screens/landing";

import PublicRoute from "./routes/Public";
import PrivateRoute from "./routes/Private";
import "./index.css";
import Header from "./components/header";
import { Box, CssBaseline, Drawer, ThemeProvider, createTheme } from "@mui/material";

import { signInAnonymously } from "firebase/auth";
import { auth } from "./api/firebase";
import Menu from "./components/menu";

import getTheme from "./components/getTheme";

const drawerWidth = 240;

const notes = ["pan tadeusz", "chlopi", "wesele", "nad niemnem", "potop", "pan kleks", "ogniem i mieczem", "granice", "pan tadeusz", "chlopi", "wesele", "nad niemnem", "potop", "pan kleks", "ogniem i mieczem", "granice"]
const defaultTheme = createTheme({});

function App(props) {
  const { window } = props;
  const { loading, user } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const myTheme = createTheme(getTheme('light'));

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <AuthProvider>
      <ThemeProvider theme={myTheme}>
        <CssBaseline>
          <Router>
            {!loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Header drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
                <Box
                  component="nav"
                  sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }}}
                  aria-label="mailbox folders"
                >
                  <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    elevation={1}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "#f4f3d7" },
                    }}
                  >
                    <Menu handleDrawerToggle={handleDrawerToggle} />
                  </Drawer>
                  <Drawer
                    variant="permanent"
                    sx={{
                      display: { xs: 'none', md: 'block' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "#f4f3d7" },
                    }}
                    open
                  >
                    <Menu handleDrawerToggle={handleDrawerClose} />
                  </Drawer>
              </Box>
              <Box sx={{  display: "flex", justifyContent: "center", flexGrow: 1, p: 3, paddingTop: '56px', width: { md: `calc(100% - ${drawerWidth}px)` } }}>
                <Routes>
                  {/* public and restricted routes */}
                  <Route element={<PublicRoute restricted user={user} />}>
                    <Route element={<Login />} path="/login" />
                    <Route element={<Signup />} path="/signup" />
                  </Route>

                  {/* public and not restricted routes */}
                  <Route element={<PublicRoute user={user} />}>
                    <Route element={<Landing />} path="/" />
                  </Route>

                  {/* protected routes */}
                  <Route path="/" element={<PrivateRoute />}>
                    <Route element={<Reader />} path="/reader/:id" exact />
                    <Route element={<Home />} path="/app" />
                  </Route>

                  {/* Not Found routes */}
                  <Route>
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </Box>
            </Box>
            )}
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;