import { forwardRef } from "react";
import { AppBar, Dialog, IconButton, Box, Typography, Toolbar } from "@mui/material"
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Keyboard, Mousewheel } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/pagination';
import { useTheme } from "@emotion/react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Reel ({ open, handleClose, data }) {
  const theme = useTheme();

  console.log(theme);
  return (<Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    sx={{ 
      "& .MuiPaper-root": { backgroundColor: theme.palette.primary.dark },
      "& .swiper-pagination-bullet-active": { backgroundColor: theme.palette.primary.light }
    }}
  >
    <AppBar sx={{ position: "fixed", background: 'transparent' }} elevation={0}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Reel
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    
    <Swiper
      direction={'vertical'}
      pagination={{
        clickable: true,
      }}
      keyboard={{ enabled: true }}
      mousewheel={true}
      modules={[Pagination, Mousewheel, Keyboard]}
      className="mySwiper">
        {data.map(text => (     
          <SwiperSlide>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, padding: "2em" }}>
              {text}
            </Typography>
          </SwiperSlide>     
        ))}
    </Swiper>
  </Dialog>)
}

export default Reel;