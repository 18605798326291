import { Box, Typography, Button } from "@mui/material"
import Signup from "./signup"
import Pricing from "../components/pricing"
import Footer from "../components/footer"
import { useContext } from "react"
import { AuthContext } from "../contexts/authContext"
import { StickyNote2TwoTone } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

function Landing () {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Box sx={{ textAlign: "center", maxWidth: "800px", justifyContent: "center", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h3" component="h3">
          Enter with knowledge <br />
          Exit with understanding
        </Typography>
        <Typography variant="h5" component="h5" >Give any text and get easy to swallow messages</Typography>
      </Box>
      {user && !user.isAnonymous ? 
        <Box sx={{ maxWidth: "400px", display: "flex", alignSelf: "center", margin: "2em", maxWidth: "400px", width: "100%" }}>
          <Button fullWidth={true} variant="contained" startIcon={<StickyNote2TwoTone />} onClick={() => navigate('/app')}>Try it now</Button> 
        </Box>  
        : <Signup />}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" component="h4">
          New thing to learn? No problem!<br />
          Three steps to get it quick!
        </Typography>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h5" component="h3">
              Paste your knowledge.
            </Typography>
            <Typography variant="p" component="p">
              This can be text from article. PDF with your latest company memo. Powerpoint with the next strategy.
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "400px", background: "blue" }}></Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h5" component="h3">
              Convert it!
            </Typography>
            <Typography variant="p" component="p">
              Our AI will convert it too bullet points keeping all necessery data in condence form!
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "400px", background: "green" }}></Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h5" component="h3">
              Read it.
            </Typography>
            <Typography variant="p" component="p">
              You can play it and read point by point like a reel without distruction.
            </Typography>
          </Box>
          <Box sx={{ width: "100%", height: "400px", background: "yellow" }}></Box>
        </Box>
      </Box>
      <Pricing />
      <Footer />
    </Box>
  )
}

export default Landing