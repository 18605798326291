import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signInAnonymously } from "firebase/auth";
import { Box, Button, Divider, TextField } from "@mui/material";
import { Google } from "@mui/icons-material";
import StickyNote2TwoTone from "@mui/icons-material/StickyNote2TwoTone";

const Signup = () => {
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const auth = getAuth();

  const provider = new GoogleAuthProvider();

  //! for getting the user email and password value
  const handleOnChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setUserCredentials((prev) => ({ ...prev, [fieldName]: fieldValue }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //! clearing error state on submit
    setError("");

    //! setting loading to true in order to show the loadin state
    setIsLoading(true);
    console.log("submit clicked");

    try {
      const { email, password } = userCredentials;
      await createUserWithEmailAndPassword(auth, email, password);

      const currentUser = auth.currentUser;
      
      console.log("currentUser", currentUser);
      navigate('/app');
    } catch (err) {
      //! getting the error message from firebase and setting it in the state to show
      setError(err?.message || "Something went wrong!");
      console.log("error", err);
    }

    //! setting the  loading to false at the end of our submit execution
    setIsLoading(false);
  };

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log("currentUser", user);

      navigate('/app');
    } catch (err) {
      setError(err?.message || "Something went wrong");
      console.log("error", err)
    }
  }

  const handleAnnounymousSignup = async () => {
    try {
      signInAnonymously(auth).then(user => {
        navigate('/app');
        console.log("currentUser", user);
      }).catch(msg => console.log(msg));
    } catch (err) {
      //! getting the error message from firebase and setting it in the state to show
      console.log("error", err);
    }
  }

  return (
    <Box sx={{ maxWidth: "400px", display: "flex", alignSelf: "center" }}>
      <form onSubmit={onSubmit} className="form">
        <Box sx={{ padding: '1em' }}>
          <TextField
            required
            type="email"
            name="email"
            placeholder="Email"
            value={userCredentials.email}
            onChange={handleOnChange}
          />
        </Box>
        <Box sx={{ padding: '1em' }}>
          <TextField
            fullWidth={true}
            required
            type="password"
            name="password"
            placeholder="Password"
            value={userCredentials.password}
            onChange={handleOnChange}
          />
        </Box>
        <Box sx={{ padding: '1em 16px' }}>
          <Button variant="contained" type="submit" disabled={isLoading} fullWidth={true}>
            {isLoading ? "Loading..." : " Signup with Password"}
          </Button>
        </Box>
        <Box sx={{ padding: '1em 16px' }}>
          <Button fullWidth={true} variant="contained" startIcon={<Google />} onClick={handleGoogleSignup}>Signup with Google</Button>
        </Box>
        <Box sx={{ padding: '1em 16px' }}>
          <Button fullWidth={true} variant="contained" onClick={handleAnnounymousSignup} startIcon={<StickyNote2TwoTone />}>Try without signup</Button>
        </Box>
        <p className="navigate-text">
          Already have an account?{" "}
          <span onClick={() => navigate("/login")}>Log In</span>{" "}
        </p>

        {error && <p className="error-text">{error}</p>}
      </form>
    </Box>
  );
};

export default Signup;