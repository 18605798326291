import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";

import { AuthContext } from "../contexts/authContext";
import { auth, db, storage } from "../api/firebase";
import { createPresentation } from "../api/ppt";
import { signOut } from "firebase/auth";

import StarterKit from "@tiptap/starter-kit";

import {
  ResizableImage,
  MenuButtonAddImage,
  MenuButtonBold,
  MenuButtonImageUpload,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor
} from "mui-tiptap";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Login from "./login";
import Reel from "../components/reel";
import { httpsCallable } from "firebase/functions";
import { functions } from "../api/firebase";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { CloudUpload, TextSnippet } from "@mui/icons-material";
import { uploadBytes, ref, imageUpload, getDownloadURL, setImageUrls } from "firebase/storage"
import Loader from "../components/loader";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Home = () => {
  const { user } = useContext(AuthContext);
  const [ open, setOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ messages, setMessages ] = useState([]);
  const [ notesId, setNotesId ] = useState();
  const [ waitingMessage, setWaitingMessage ] = useState();
  const [ waitingTitle, setWaitingTitle ] = useState();
  const [ files, setFiles ] = useState([]);
  const rteRef = useRef(null);
  
  const getData = async (id) => {
    const q = query(collection(db, "cards"), where("notesId", "==", id || ""), orderBy("order"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
          cities.push(doc.data().message);
      });
      console.log('dupa');
      setMessages(cities);
    });
  }

  const navigate = useNavigate();

  const logOut = async () => {
    await signOut(auth)
      .then(() => {
        console.log("user signed out");
      })
      .catch((err) => {
        console.log("err user signOut ", err);
      });
  };
  
  const convert = httpsCallable(functions, 'convert');
  
  const handleconvertToPpt = async () => {
    const notesId = window.crypto.randomUUID();
    setIsLoading(true);
    setNotesId(notesId);
    const currentFiles = await getFilesFromBlobs(rteRef.current?.editor?.getHTML());
    debugger;
    const response = await convert({ prompt: rteRef.current?.editor?.getHTML(), files: currentFiles, notesId});
    
    getData(response.data.data);
  }
  
  const handleConvert = async () => {
    setIsLoading(true);
    const response = await convert({ prompt: rteRef.current?.editor?.getHTML()});
    
    setNotesId(response.data.data);
    
    getData(response.data.data);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setIsLoading(false);
    setOpen(true);
  }

  const getFilesFromBlobs = async (html) => {
    const notesId = window.crypto.randomUUID();
    const htmlDoc = document.createElement( 'html' );
    htmlDoc.innerHTML = html;

    const imagesNodes = htmlDoc.getElementsByTagName('img');
    const images = Array.prototype.slice.call( imagesNodes );
    const tempFiles = []
    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      const src = img.getAttribute('src');
      const blob = await fetch(src).then(r => r.blob());
      const fileRef = ref(storage, `images/${notesId}_${i}`);
      const metadata = {
        customMetadata: {
          uid: user.uid,
          notesId
        }
      };
      await uploadBytes(fileRef, blob, metadata);
      debugger;
      tempFiles.push(`images/${notesId}_${i}`);
      
    };
    debugger;
    setFiles(tempFiles);
    return tempFiles;
  }

  const handleFileChange = (e) => {
    // setIsLoading(true);
    // setWaitingMessage('It will take some time to read the document and create. Once it is ready you can see flashcards, bullet points or reader view.');
    // setWaitingTitle('We are uploading your file');
    // const notesId = window.crypto.randomUUID()
    // const file = e.target.files[0];
    // let fileRef;
    // if (file.type.includes('image')) {
    //   fileRef = ref(storage, `images/${file.name}`);
    // } else {
    //   fileRef = ref(storage, `documents/${file.name}`);
    // }
    // const metadata = {
    //   customMetadata: {
    //     uid: user.uid,
    //     notesId
    //   }
    // };
    // setNotesId(notesId);
    // uploadBytes(fileRef, file, metadata).then((snapshot) => {
    //   getData(notesId);
    // });
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      rteRef.current?.editor?.chain().focus().setImage({ src: URL.createObjectURL(files[i]) }).run()
    }
  }

  const handleDrop = (view, e) => {
    e.preventDefault();
    const files = e.dataTransfer?.files;
    
    for (let i = 0; i < files.length; i++) {
      rteRef.current?.editor?.chain().focus().setImage({ src: URL.createObjectURL(files[i]) }).run()
    }
  }

  return (
    
    <Box sx={{
      width: "100%",
      height: "calc(100vh - 80px)",
      display: "flex",
      flexDirection: "column",
      "& .MuiTiptap-RichTextContent-root": {
        minHeight: "100%"
      },
      "& .MuiTiptap-FieldContainer-root": {
        height: "100%"
      },
      "& .MuiTiptap-MenuBar-root": {
        backgroundColor: "#f4f3d7",
        minHeight: "50px !important"
      },
      "& .MuiTiptap-FieldContainer-root": {
        overflow: "scroll"
      },
      "& .MuiTiptap-FieldContainer-outlined": {
        border: "none",
        borderRadius: 0,
        padding: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column"
      },
      "& .css-anumxs-FieldContainer-notchedOutline": {
        border: "none",
        width: "100%"
      },
      "& .tiptap.ProseMirror": {
        width: "100%",
        height: "100%",
        overflow: "scroll",
        wordBreak: "break-all"
      }
    }}>
      {!waitingMessage ? <RichTextEditor
        ref={rteRef}
        extensions={[StarterKit, ResizableImage]} // Or any Tiptap extensions you wish!
        content="<h1>Paste any text and get notes</h1>" // Initial content for the editor
        onUpdate={(a,b,c) => console.log('ON UPDATE: ', a, b, c)}
        onSelectionUpdate={(a,b,c) => console.log('ON SELECTION: ', a, b, c)}
        editorProps={{
          handleDrop: handleDrop
        }}
        
        // Optionally include `renderControls` for a menu-bar atop the editor:
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuButtonStrikethrough />
            {/* Add more controls of your choosing here */}
          </MenuControlsContainer>
        )}
      /> : <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", overflow: "scroll" }}>
        <Typography variant="h3" component="h3">
          {waitingTitle}
        </Typography>
        <Typography variant="h5" component="h5" >
          {waitingMessage}
        </Typography>
        <Loader />
        <Typography variant="h6" component="h6" >
          When ready you can find it in left panel or here: 
        </Typography>
      </Box>}
      <Box sx={{ display: "flex" }}>
        {!messages || messages.length === 0 ? <Button 
          fullWidth={true} 
          variant="contained"
          startIcon={!isLoading ? <TextSnippet /> : <CircularProgress size={18} color="inherit"/>}
          onClick={handleconvertToPpt}>
            Convert
        </Button> : null}
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUpload />}
        >
          Upload file
          <VisuallyHiddenInput onChange={handleFileChange} type="file" />
        </Button>
        {messages.length ? <Navigate to={`/reader/${notesId}`} /> : null}
      </Box>
      {messages && messages.length ? <Reel handleClose={handleClose} open={open} data={messages} /> : null}
    </Box>
  );
};

export default Home;